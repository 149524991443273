<!--
 * @Author: yanzq
 * @Date: 2021-12-17 23:50:22
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-01-07 10:51:47
 * @Description: 请填写简介
-->
<template>
  <div class="uesrCenter">
    <div class="banner flex-y-s">
      <a href="javasctipt:;" @click="backLevel" class="backBtn">返回</a>
      <a class="homeBtn" @click="backHome" href="javascript:;">首页</a>
      <div>
        <!-- <h3>名称名称</h3> -->
        <p>欢迎您！{{ $store.state.globalInfo.nickname }}</p>
      </div>
    </div>
    <ul>
      <li>
        <div>
          <img src="../../assets/images/userCenter/qy.png" alt="" />
          企业名称
        </div>
        <div>
          <img src="../../assets/detail/jwd.png" alt="" />
          状态
        </div>
        <div>
          <img src="../../assets/images/userCenter/bz.png" alt="" />
          企业群组
        </div>
      </li>
    </ul>
    <div class="list">
      <div
        v-for="item in list"
        :key="item.uuid"
        @click="toInformation(item)"
        class="item"
      >
        <div class="blod">{{ item.waCompanyName }}</div>
        <div>{{ item.waBind == "1" ? "已绑定" : "未绑定" }}</div>
        <div>{{ item.waGroup }}</div>
        <img
          @click.stop="del(item)"
          src="@/assets/images/del.png"
          alt=""
          style="width: 20px; cursor: pointer"
        />
      </div>
    </div>
    <div class="fix">
      <van-button type="info" to="index" @click="clickBd"
        >查找我的企业</van-button
      >
    </div>

    <van-popup v-model="show" style="width: 85%">
      <div
        class="banner pop-banner"
        style="height: 80px; justify-content: center"
      >
        <!-- <img src="../../assets/images/userCenter/edit.png" alt="" />
        <van-field
          v-model="waCompanyName"
          required
          name="waCompanyName"
          placeholder="请填写企业名称（必填）"
          :rules="[{ required: true, message: '请填写企业名称' }]"
        /> -->
        <div class="title">查询条件</div>
      </div>
      <van-form @submit="onSubmit">
        <van-cell-group inset>
          <van-field
            class="flex-block"
            required
            v-model="waAccountName"
            name="waAccountName"
            label="法人姓名"
            placeholder="请填写法人姓名（必填）"
            :rules="[{ required: true, message: '请填写统一法人姓名' }]"
          />
          <van-field
            class="flex-block"
            required
            v-model="password2"
            name="password2"
            maxlength="4"
            label="法人身份证号码(后四位)："
            placeholder="请填写法人身份证号码（必填）"
            :rules="[
              { required: true, message: '请填写统一法人身份证号码后四位' },
            ]"
          />
        </van-cell-group>
        <div
          style="margin: 16px; display: flex; justify-content: space-between"
        >
          <van-button
            round
            block
            @click="show = false"
            native-type="button"
            style="
              background: #ffffff;
              border: 2px solid #3295f9;
              color: #3295f9;
              height: 36px;
              width: 46%;
            "
          >
            取消
          </van-button>
          <van-button
            round
            block
            type="info"
            native-type="submit"
            style="
              width: 46%;
              background-image: linear-gradient(
                90deg,
                #2d90fb 0%,
                #84bfff 100%
              );
              height: 36px;
            "
          >
            提交
          </van-button>
        </div>
      </van-form>
    </van-popup>
  </div>
</template>

<script>
import {
  getEnterpriseSupervisionList,
  saveAccountAndCompany,
  getWechatEnterpriseSupervisionCount,
  unboundAccountAndCompany,
} from "../../api/home";
import { mapMutations, mapActions } from "vuex";
export default {
  data() {
    return {
      show: false,
      formData: {},
      waAccountName: "",
      password2: "",
      list: [],
      waCompanyName: "",
    };
  },

  mounted() {
    this.request();
  },

  methods: {
    ...mapActions(["commitSetGlobalInfo"]),
    del(item) {
      if (item.waBind == "1") {
        this.$toast("请先解绑才能进行删除");
      } else {
        unboundAccountAndCompany({
          waOpenId: localStorage.getItem("openid"),
          // waOpenId: "o3JQ06uMfbv9nWloUy-sWAczOCkA",
          waShxydm: item.waShxydm,
        }).then((res) => {
          if (res.success) {
            this.$toast("删除成功");
            this.request();
          } else {
            this.$toast("删除失败");
          }
        });
      }
    },
    backLevel(e) {
      e.preventDefault();
      this.$router.push({
        path: "/",
      });
      return false;
    },
    backHome(e) {
      e.preventDefault();
      wx.closeWindow();
      return false;
    },
    toInformation(item) {
      this.$router.push({
        path: "/bindCorporation",
        query: {
          item: JSON.stringify(item),
        },
      });
    },
    //获取法人企业列表
    onSubmit() {
      // 获取法人企业列表
      let formData = new FormData();

      getEnterpriseSupervisionList({
        waAccountName: this.waAccountName,
        waOpenId: this.password2,
      }).then((res) => {
        this.show = false;
        if (res.data.length == 0) {
          this.$toast.fail("查询无企业");
          this.waAccountName = "";
          this.password2 = "";
          this.show = false;
          return false;
        }
        const arr = [];
        res.data.forEach((item) => {
          let obj = {
            waCompanyName: item.enterpriseName,
            waShxydm: item.shxydm,
            waAccountName: this.waAccountName,
            waOpenId: localStorage.getItem("openid"),
            // waOpenId: "o3JQ06uMfbv9nWloUy-sWAczOCkA",
          };
          arr.push(obj);
        });
        // 个人中心保存法人
        saveAccountAndCompany(arr).then((res) => {
          if (res.success) {
            this.commitSetGlobalInfo(
              Object.assign(this.$store.state.globalInfo, {
                nickname: this.waAccountName,
              })
            );
            this.request();
            // this.$router.push({
            //   path: "/userCenter",
            // });
          }
        });
      });
    },
    //查找我的企业
    clickBd() {
      this.show = true;
    },
    //获取法人绑定企业数量
    request() {
      // 获取绑定企业列表
      getWechatEnterpriseSupervisionCount({
        waOpenId: localStorage.getItem("openid"),
        // waOpenId: "o3JQ06uMfbv9nWloUy-sWAczOCkA",
      }).then((res) => {
        if (res.success) {
          this.list = res.data;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.flex-block {
  display: block;
  padding-bottom: 0;
  ::v-deep.van-field__label {
    width: 100%;
    font-size: rem(28);
    color: #333333;
    margin-bottom: 5px;
    text-indent: 10px;
  }
}
::v-deep .van-field__body {
  background: rgba(238, 238, 238, 0.6);
  border-radius: 4px;
  width: 100%;
  height: rem(66);
}
::v-deep .van-cell__value {
  display: flex;
  flex-wrap: wrap !important;
  align-items: center;
}

.banner {
  padding: rem(20);
  width: 100%;
  height: rem(342);
  background: url("../../assets/images/userCenter/banner-bg2.png");
  background-size: 100%;
  color: white;
  .title {
    color: #fff;
    font-size: rem(40);
  }
  p {
    font-size: rem(36);
    font-weight: bold;
  }
  img {
    width: rem(36);
    height: rem(36);
  }
}
.pop-banner {
  display: flex;
  align-items: center;

  ::v-deep .van-field__body {
    width: 250px;
    border: none !important;
    margin-left: 5px;
    background: none;
    position: relative;
    &::before {
      display: block;
      content: "";
      width: 220px;
      position: absolute;
      height: 2px;
      background: white;
      top: 30px;
      left: -25px;
    }
  }

  .van-cell {
    padding: 0;
    background: none;

    display: block !important;
  }
  ::-webkit-input-placeholder {
    color: white !important;
  }
  .van-cell--required::before {
    display: none;
  }
}
::v-deep.van-cell--required .van-field__label::after {
  content: "";
  position: absolute;
  left: rem(36);
  top: rem(30);
  width: rem(6);
  height: rem(28);
  background-image: linear-gradient(179deg, #2d90fb 0%, #84bfff 100%);
  border-radius: 40px;
}
.fix {
  position: fixed;
  bottom: 0;
  left: 0;
  height: rem(96);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.19);

  width: 100%;
  .van-button {
    width: rem(510);
    height: rem(76);
    background-image: linear-gradient(90deg, #2d90fb 0%, #84bfff 100%);
    box-shadow: 0 4px 8px 0 rgba(25, 31, 37, 0.12);
    border-radius: 40px;
  }
}

ul {
  margin: rem(20) auto;
  box-sizing: border-box;
  width: rem(686);
  position: relative;
  top: -40px;
  text-align: center;

  li {
    width: 100%;
    display: flex;
    img {
      width: rem(30);
      height: rem(32);
      position: relative;
      top: 2px;
    }
  }

  div {
    width: 50%;
    text-align: center;
    padding: rem(25) 0;
    font-size: rem(25);
  }
  .blod {
    font-size: rem(24);
    color: #3296fa;
  }
  & > li:first-child {
    background: #fafdff;
    height: rem(96);
    box-shadow: 0 rem(2) rem(16) 0 rgba(0, 0, 0, 0.09);
    border-radius: rem(48);
    position: relative;
    & > div {
      position: relative;
      &:not(:nth-last-of-type(1)):before {
        content: "";
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        display: block;
        width: rem(3);
        height: rem(36);
        background: #3296fa;
      }
    }
  }
}
.list {
  margin: rem(20) auto;
  box-sizing: border-box;
  width: rem(686);
  position: relative;
  top: -40px;
  text-align: center;

  .item {
    width: 100%;
    display: flex;
    align-items: center;
    margin: rem(15) 0 0;
    div {
      flex: 1;
    }
  }
  .blod {
    font-size: rem(24);
    color: #3296fa;
  }
}
.uesrCenter {
  padding-bottom: rem(100);
}
::v-deep .van-field__error-message {
  display: none !important;
}
</style>
